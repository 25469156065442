.banner {
  background-color: var(--color-surface-quinary);
  padding: 10px 20px;
  color: var(--color-default-secondary);
  font-size: var(--fs-small);

  p {
    margin: 0;
  }

  span {
    text-decoration: underline;
  }

  a {
    color: inherit;
    font-weight: bold;
  }
}
